









import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class FlipNumberItem extends Vue {
  @Prop() private readonly value!: number;
  @Prop() private readonly speed!: number;

  backCount = this.value;
  frontCount = 0;
  animationClass = "";
  isFlipping = false;

  @Watch("value")
  watchValue(newVal: number, oldVal: number) {
    if (newVal < oldVal) {
      this.flipUp(newVal, oldVal);
    } else if (newVal > oldVal) {
      this.flipDown(newVal, oldVal);
    }
  }

  async flipDown(newVal: number, oldVal: number) {
    while (newVal > oldVal) {
      // console.log(new Date().toTimeString())
      await this.flipDownOne(oldVal);
      oldVal++;
    }
  }

  async flipUp(newVal: number, oldVal: number) {
    while (newVal < oldVal) {
      await this.flipUpOne(oldVal);
      oldVal--;
    }
  }

  async flipDownOne(num: number) {
    if (this.isFlipping) {
      return false;
    }
    this.frontCount = num;
    this.backCount = this.frontCount >= 9 ? 0 : this.frontCount + 1;
    this.animationClass = "down go";
    this.isFlipping = true;
    await this.resetClass("down");
  }

  async flipUpOne(num: number) {
    if (this.isFlipping) {
      return false;
    }
    this.frontCount = num;
    this.backCount = this.frontCount <= 0 ? 9 : this.frontCount - 1;
    this.animationClass = "up go";
    this.isFlipping = true;
    await this.resetClass("up");
  }

  resetClass(type: string) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this.animationClass = type;
        this.isFlipping = false;
        this.frontCount = this.backCount;
        setTimeout(() => {
          resolve();
        }, 0);
      }, this.speed);
    });
  }
}
