








import { Component, Vue } from "vue-property-decorator";
import FlipNumber from "@/components/number/FlipNumber.vue";

@Component({
  components: { FlipNumber },
})
export default class test extends Vue {
  value = "0000";

  handleAdd() {
    this.value = this.value === "0000" ? "1098" : "0000";
  }
}
